// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  googleMapsApiKey: 'AIzaSyA9eg8e7FW-cgh-jvB8uEwFBtBmTBL3sSk',
  production: false,
  bookstackApiUrl: "http://192.168.168.244",
  bookstackApiToken: "C6WOmKRjbTDw5mDa34PPm4rJX3gZGkPU:XHoC6Qi02xr0wRi6fVv9I5BwLd0MpyWk",
  enableDebug: true,
  server: "https://mona-ai.online:8599",
  microsoftClientId: '1836438d-bf84-4b30-83f5-20d0950d841a',
  microsoftRedirectUri: 'https://your-firebase-app.firebaseapp.com/__/auth/handler',
  firebase: {
    apiKey: "AIzaSyBj4xsSP9AojxvFI6s_329reBdxuRxIja4",
    authDomain: "mona-ai-pwa-firebase.firebaseapp.com",
    projectId: "mona-ai-pwa-firebase",
    databaseURL: "https://mona-ai-pwa-firebase-default-rtdb.europe-west1.firebasedatabase.app",
    storageBucket: "mona-ai-pwa-firebase.appspot.com",
    messagingSenderId: "219198639425",
    appId: "1:219198639425:web:809db39647f61021d565c0",
    measurementId: "G-C6ZN8GKHY3"
  },
  coolstore: {
    apiKey: "AIzaSyBj4xsSP9AojxvFI6s_329reBdxuRxIja4",
    authDomain: "mona-ai-pwa-firebase.firebaseapp.com",
    projectId: "mona-ai-pwa-firebase",
    storageBucket: "mona-ai-pwa-firebase.appspot.com",
    messagingSenderId: "219198639425",
    appId: "1:219198639425:web:809db39647f61021d565c0"
  },
  perfectstore: {
    apiKey: "AIzaSyDj9-imxQz-cI4WGi-NJq2jZDprXnhk9eE",
    authDomain: "mona-plattform.firebaseapp.com",
    projectId: "mona-plattform",
    storageBucket: "mona-plattform.appspot.com",
    messagingSenderId: "922760807427",
    appId: "1:922760807427:web:c21e0d18244fa96dbf3a36",
    measurementId: "G-2TZK54JBJD"
  },
  videoStore: {
    apiKey: "AIzaSyAsXi2FeUKqS7STDl3UtfFlSy8m8RDXjRA",
    authDomain: "mona-videos-ccd72.firebaseapp.com",
    projectId: "mona-videos-ccd72",
    storageBucket: "mona-videos-ccd72.appspot.com",
    messagingSenderId: "786401769141",
    appId: "1:786401769141:web:535f64199eb07be438b0fc"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

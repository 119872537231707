
export class FirebaseConstants {

    public static jobOffers = "joboffers";
    public static deletedJobOffers = "deletedJobOffers";
    public static applicants = "applicants";
    public static applicantProfile = "applicantProfile";
    public static bewerber = "bewerber";
    public static contracts = "vorlagen";
    public static messages = "messages";
    public static variables = "variables";
    public static videos = "videos";
    public static teammembers = "teammembers";
    public static customers = "customers";
    public static workflows = "workflows";
    public static codingapplicants = "codingapplicants";
    public static coderbyteapplicants = "coderbyteapplicants";
    public static companies = "companies";
    public static aiCVFeedback = "aiCVFeedback";
    public static howtos = "howTos";
    public static branding = "branding";
    public static blacklist = "blacklist";
    public static matchingSettings = "matchingSettings";
    public static dashboardSettings = "dashboardSettings";
    public static emailapplicants = "emailapplicants";
    public static kundennummer = "kundennummer";
    public static agents = "agents";
    public static ChatHistory = "MONARetrieval01";
    public static deletedApplicants = "deletedApplicants";
    public static videosInProgress = "videosInProgress";
    public static monaLogs = "applicantErrors";
    public static errors = "errors";
}
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
// import firebase from 'firebase/app';
import firebase from 'firebase/app';
// require('firebase/auth')
import { Observable, from, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
// import * as fire from "firebase/app";

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  userProfile: firebase.firestore.DocumentReference;
  public currentUser: firebase.User;
  user: any;
  profileLanguage: any;
  public userStatus: any;
  public mandant: any;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private firestore: AngularFirestore
  ) {
    // firebase.auth().onAuthStateChanged(user => {
    //   if (user) {
    //     this.currentUser = user;
    //     console.log(user);
    //     this.userProfile = firebase.firestore().doc(`/userProfile/${user.uid}`);
    //   }
    // });
  }

  getUserProfile(): firebase.firestore.DocumentReference {
    return this.userProfile
  }

  giveUserProfile() {
    return this.user;
  }

  getFirstName() {
    var firstname: any;
    var uid = this.getCurrentUser().uid;
    firebase.firestore().collection("customers").doc(uid).get()
      .then((snap) => {
        console.log(snap);
        var snap2 = snap.data();
        console.log(snap2);
        firstname = snap2;
        return firstname;
      });
    return firstname;
  }

  saveTeamMemberMandant(mandant: string) {
    this.mandant = mandant;
  }

  retreiveTeamMemberMandant() {
    return this.mandant || "";
  }

  saveTeamMemberStatus(status: string) {
    this.userStatus = status;
  }

  retreiveTeamMemberStatus() {
    return this.userStatus;
  }

  getLastName() {

  }

  getCurrentUser() {
    return firebase.auth().currentUser;
  }

  getCurrentUserId() {
    return firebase.auth().currentUser.uid;
  }

  getUserDetail(): firebase.firestore.DocumentReference {
    return firebase.firestore().collection("customers").doc("9OGSyZH5qn9nvM8riGu3");
  }

  updateEmail(newEmail: string, password: string): Promise<any> {
    const credential: firebase.auth.AuthCredential = firebase.auth.EmailAuthProvider.credential(
      this.currentUser.email,
      password
    );

    return this.currentUser
      .reauthenticateAndRetrieveDataWithCredential(credential)
      .then(() => {
        this.currentUser.updateEmail(newEmail).then(() => {
          this.userProfile.update({ email: newEmail });
        });
      })
      .catch(error => {
      });
  }

  resetPassword(email) {
    firebase.auth().sendPasswordResetEmail(email).then(function () {
      console.log("email sent");
    }).catch(function (err) {
      console.log(err);
    })
  }

  
  updatePasswordNew(newPassword: string) {
    const user = firebase.auth().currentUser;
    console.log("user", user)
    user.updatePassword(newPassword).then(() => {
      console.log("successfull")
      // Update successful.
    }).catch((error) => {
      // An error ocurred
      // ...
    });
  }

  updatePassword(newPassword: string, oldPassword: string, email): Promise<any> {
    const credential: firebase.auth.AuthCredential = firebase.auth.EmailAuthProvider.credential(
      email,
      oldPassword
    );

    return this.currentUser
      .reauthenticateWithCredential(credential)
      .then(() => {
        this.currentUser.updatePassword(newPassword).then(() => {
        });
      })
      .catch(error => {
      });
  }

  Authlink() {
    var email = "felix.adam@ergosoftware.de"
    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: 'https://www.example.com/finishSignUp?cartId=1234',
      // This must be true.
      handleCodeInApp: true,
      iOS: {
        bundleId: 'com.example.ios'
      },
      android: {
        packageName: 'com.example.android',
        installApp: true,
        minimumVersion: '12'
      },
      dynamicLinkDomain: 'example.page.link'
    };
    firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings);
  }


}


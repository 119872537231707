
export class TranslationObject {
        public static TRANSLATION_OBJECT = {

                //app-component.html
                SalesEmails: "Sales-Emails",
                WasGibtsNeues: "Was gibt's neues?",
                NeustartTutorial: "Neustart-Tutorial",
                HowToVideos: "How-to Videos",
                Hilfe: "Hilfe",
                Dokumentation: "Dokumentation",

                //add-node-popover.html
                Dashboard: "Dashboard",
                Löschen: "Löschen",
                Abbrechen: "Abbrechen",
                Ok: "Ok",
                Bedingung: "Bedingung",
                Ja: "Ja",
                Nein: "Nein",
                gleich: "gleich",
                ungleich: "ungleich",
                größergleich: "größer gleich",
                kleinergleich: "kleiner gleich",
                keineBedingung: "keine Bedingung",
                Antwort: "Antwort",
                keineAntworten: "keine Antworten",
                Aktion: "Aktion",
                Verbindenmit: "Verbinden mit",
                VerbindenMitMussGefülltsein: "Verbinden mit muss gefüllt sein.",

                //assessment-test-information.html
                FrageFürDieBewertungHinzufügen: "Frage für die Bewertung hinzufügen",
                GebenSieEinenTitelFürDieFeststellungsprüfungAn: "Geben Sie einen Titel für den Einstellungstest an",
                GebenSieEineBewertungsfrageEin: "Geben Sie eine Bewertungsfrage ein",
                Frage: "Frage",
                RichtigeAntwort: "Richtige Antwort",
                FalscheAntwort: "Falsche Antwort",
                WerkzeugeBearbeiten: "Werkzeuge bearbeiten",
                TestGenerieren: "Test generieren",
                UpdateTest: "Test aktualisieren",

                //assessment-test.html
                AssessmentTestErstellen: "Einstellungstest erstellen",
                AssessmentTitle: "Titel",

                //new-assessment-test.html
                DerTitelExistiertBereitsErstellenSieEinenEinzigartigenTitel: "Der Titel existiert bereits. Erstellen Sie einen einzigartigen Titel.",

                //create-workflow.html
                BasicWorkflows: "Grundlegende Workflows",
                UseWorkflow: "Workflow verwenden",
                AdvancedWorkflows: "Erweiterte Workflows",
                NeuenWorkflowErstellen: "Neuen Workflow erstellen",
                EmailSendenBeiBewerbungseingang: "Email senden bei Bewerbungseingang",
                sendeEineEmailWennBineBewerbungEingegangenIst: "sende eine Email, wenn eine Bewerbung eingegangen ist",
                EmailSendenWennBewerberImOnboardingIst: "Email senden wenn Bewerber im Onboarding ist",
                sendeEineEmailWennSichDerBewerbendeImOnboardingBefindet: "sende eine Email, wenn sich der Bewerbende im Onboarding befindet",
                ProfilAnKundeVerschicken: "Profil an Kunde verschicken",
                sendeEineEmailMitDemBewerberprofilAnDenKunden: "sende eine Email mit dem Bewerberprofil an den Kunden",
                ProfilAnDisponentVerschicken: "Profil an Disponent verschicken",
                sendeEineEmailMitDemBewerberprofilAnDenDisponenten: "sende eine Email mit dem Bewerberprofil an den Disponenten",
                MatcheBewerberAufKriteriumSendeNotizAnDisponenten: "Matche Bewerber auf Kriterium, sende Notiz an Disponenten",
                SchickeInformationenNachErfolgreichemInterview: "Schicke Informationen nach erfolgreichem Interview",
                schickeSpezifischeInformationenZumBewerberDamitManWeißWieEsWeitergeht: "schicke spezifische Informationen zum Bewerber, damit man weiß, wie es weitergeht.",
                SchickeDatenAnSchnittstellen: "Schicke Daten an Schnittstellen",
                updateInformationenInATSSystem: "update Informationen in ATS-System",
                fahreEchtzeitUpdatesInDeinemATSSystem: "fahre Echtzeit-Updates in deinem ATS-System",
                updateInformationenInERPSystem: "update Informationen in ERP-System",
                fahreEchtzeitUpdatesInDeinemERPSystem: "fahre Echtzeit-Updates in deinem ERP-System",
                leiteWeiterZurWebseite: "leite weiter zur Webseite:",
                leiteDiePersonWeiterZuEinerWebseite: "leite die Person weiter zu einer Webseite",
                ProfilErstellenNachBewerbungseingangOhneInterview: "Profil erstellen nach Bewerbungseingang ohne Interview",
                erstelleEinProfilOhneInterview: "erstelle ein Profil ohne Interview",
                ProfilMatchenUndVersendenAKunden: "Profil matchen und versenden an Kunden",
                ProfilMatchenUndVersendenAnDisponent: "Profil matchen und versenden an Disponent",
                matcheUndVersendeDasProfilAnDenKunden: "matche und versende das Profil an den Kunden",
                AnrufSendenWennBewerberInsichBeworbenHat: "Anruf senden wenn Bewerber/-in sich beworben hat",
                sendeEinenAnrufWennDerBewerbendeDasOnboardingBeendetHat: "sende einen Anruf, wenn der Bewerbende das Onboarding beendet hat",
                SMSSendenWennBewerberInSichBeworbenHat: "SMS senden wenn Bewerber/-in sich beworben hat",
                sendeEineSMSWennDerBewerbendeDasOnboardingErfolgreichBeendetHat: "sende eine SMS, wenn der Bewerbende das Onboarding erfolgreich beendet hat",
                SendeDankesmailAnBewerber: "Sende Dankesmail an Bewerber",
                sendetDankesmailAnBewerberNachDemBewerbungsprozess: "sendet Dankesmail an Bewerber nach dem Bewerbungsprozess",
                SendeVertragskopieAnBewerber: "Sende Vertragskopie an Bewerber",
                sendetEineKopieDesVertragsAnDenBewerber: "sendet eine Kopie des Vertrags an den Bewerber",
                TerminÜberCalendlyBuchen: "Termin über Calendly buchen",
                DerBewerberKannEinenTerminÜberCalendlyBeiIhnenBuchen: "Der Bewerber kann einen Termin über Calendly bei Ihnen buchen.",

                zumZeitpunkt: "zum Zeitpunkt",
                vierhDanach: "4h danach",
                vierundzwanzighDanach: "24h danach",
                achtundvierzighDanach: "48h danach",
                benutzerdefinierteZeit: "benutzerdefinierte Zeit",
                BenutzerdefinierteZeit: "Benutzerdefinierte Zeit",
                nichtWiederholen: "nicht wiederholen",
                wiederholen: "Wiederholen",
                every: "jede",
                after: "nach",
                PleaseEnteraNumberFrom1to30: "Bitte geben Sie eine Zahl von 1 bis 30 ein.",
                täglich: "täglich",
                wöchentlich: "wöchentlich",
                monatlich: "monatlich",
                mon: "Mon",
                di: "Di",
                mi: "Mi",
                do: "Do",
                fr: "Fr",
                sa: "Sa",
                so: "So",
                Bewerberprofil: "Bewerberprofil",
                BewerberprofilFürKunden: "Bewerberprofil für Kunden",
                Vertragsdokumente: "Vertragsdokumente",
                attachments: "Anhänge",
                dynamicAttachments: "Dynamische Anhänge",
                API: "API",
                Flowchartknotenpunkt: "Flowchart-Knotenpunkt",
                PromptWorkflow: "Prompt-Workflow",
                agentWorkflow: "Agent-Workflow",
                OnboardingBeendet: "Onboarding beendet",
                WiederkehrenderWorkflow: "Wiederkehrender Workflow",
                InterviewBeendet: "Interview beendet",
                VertragUnterschrieben: "Vertrag unterschrieben",
                mandantenMatchingEinzeln: "Mandanten-Matching einzeln",
                geoMappingEinzeln: "Geo-Mapping einzeln",
                geoMappingMehrfach: "Geo-Mapping mehrfach",
                alleAusführen: "alle ausführen",
                KriteriumErfüllt: "Kriterium erfüllt",
                mehrereKriterienErfüllt: "mehrere Kriterien erfüllt",
                Disponent: "Disponent",
                Kunde: "Kunde",


                //dashbaord.html (workflow dashboard)
                Workflows: "Workflows",
                Name: "Name",
                Inhalt: "Inhalt",
                Medium: "Medium",
                Zyklus: "Zyklus",
                zumEreigniszeitpunkt: "zum Ereigniszeitpunkt",
                nach4Stunden: "nach 4 Stunden zw. 09:00-17:00 lokale Zeit",
                nach24Stunden: "nach 24 Stunden zw. 09:00-17:00 lokale Zeit",
                nach48Stunden: "nach 48 Stunden zw. 09:00-17:00 lokale Zeit",
                jederzeit: "jederzeit",
                bewerberContingent: "Bewerber-Contingent im Jahr",

                //edit-workflow.html
                WorkflowName: "Workflow Name",
                EventTypes: "Welche Ereignistypen gelten für diesen Workflow?",
                bitteAuswählen: "Bitte auswählen",
                BeschreibeDenProzess: "Beschreibe den Prozess...",
                DateAndTime: "Datum und Uhrzeit:",
                Repeat: "Wiederholen:",
                Every: "Jede:",
                days: "Tage",
                weeks: "Wochen",
                months: "Monate",
                checkLogsForTranslation: "Übersetze Antworten",
                End: "Ende:",
                repeatations: "Wiederholungen",
                WennDasPassiert: "Wenn das passiert",
                StatusDesWorkflow: "Status des Workflows",
                UntergeordneteWorkflows: "Untergeordnete Workflows",
                UntergeordneteMatchingOptionen: "Untergeordnete Matching-Optionen",
                dannSchickeDiesesMedium: "dann schicke dieses Medium",
                Informationen: "Informationen",
                InformationenAnpassen: "Informationen anpassen",
                EMailTextAnpassen: "E-Mail-Text anpassen",
                Betreff: "Betreff",
                Nachricht: "Nachricht",
                Anhänge: "Anhänge",
                BetreffFallsLleerDannWirdDerBetreffPassendDynamischGesetzt: "Betreff (falls leer, dann wird der Betreff passend dynamisch gesetzt)",
                DateiWählen: "Datei wählen",
                dynamischeAnhänge: "Dynamische Anhänge",
                NachrichtOptional: "Nachricht (optional)",
                dynamischenAnhangAuswählen: "Dynamischen Anhang auswählen",
                VorlageWählenOptional: "Vorlage wählen (optional)",
                DokumentHochladenNurDOCX: "Dokument hochladen (nur .docx)",
                dynamischeAPIDaten: "Dynamische API-Daten",
                anDieseEmpfänger: "An diese Empfänger",
                EMailAdresseDesEmpfängersUndAufPLUSklicken: "E-Mail Adresse des Empfängers und auf PLUS klicken",
                Landesvorwahl: "Landesvorwahl",
                Telefonnummer: "Telefonnummer",
                DieEMailExistiertBereits: "Die E-Mail existiert bereits!",
                DieTelefonnummerExistiertBereits: "Die Telefonnummer existiert bereits!",
                KalenderLink: "Kalender Link",
                KalenderLinkEinfügen: "Kalender Link einfügen",
                Speichern: "Speichern",
                Prompt: "Prompt",
                Agent: "Agent",
                BewerberprofilFürDirektvertrieb: "Bewerberprofil für Direktvertrieb",

                //contacting-text.html
                EMailBetreff: "E-Mail Betreff",
                HierKannDerStandardtextDesEMailBetreffsEingegebenWerden: "Hier kann der Standardtext des E-Mail Betreffs eingegeben werden.",
                EMailText: "E-Mail Text",
                Vorname: "Vorname",
                Nachname: "Nachname",
                Firmenname: "Firmenname",
                Link: "Link",
                HierKannDerStandardtextZurEMailKontaktierungEingegebenWerden: "Hier kann der Standardtext zur E-Mail-Kontaktierung eingegeben werden.",
                SMSText: "SMS Text",
                HierKannDerStandardtextZurSMSKontaktierungEingegebenWerden: "Hier kann der Standardtext zur SMS-Kontaktierung eingegeben werden.",

                //conditional-flowchart.html
                conditionalFlowchart: "Flowchart",

                //contract-information.html
                Lesen: "Lesen",
                Messages: "Nachrichten",
                Variables: "Variablen",
                NeueNachrichtHinzufügen: "Neue Nachricht hinzufügen",
                neueNachricht: "neue Nachricht",
                VariabelMatching: "Variabel Matching",
                VariableAusVertrag: "Variable aus Vertrag",
                Konfigurieren: "Konfigurieren",

                //contracts.html
                VertragHinzufügen: "Vertrag hinzufügen",
                Vetragsname: "Vertragsname",
                ErstelltAm: "Erstellt am",

                //new-contract.html
                WähleEineDatei: "Wähle eine Datei",
                aufCVAnalyseSchalten: "auf CV Analyse schalten",
                aufVertragsAnalyseSchalten: "auf Vertrags Analyse schalten",
                DragAndDropDateiHier: "Drag and drop Datei hier",
                Erklärungen: "Erklärungen",

                //dashboard.html
                MONADashboard: "MONA-Dashboard",
                logout: "Logout",
                aktuellerMonat: "aktueller Monat",
                BewerberGesamt: "Bewerber gesamt:",
                ZeitDisponent: "Zeit Disponent:",
                Minuten: "Minuten",
                ZeitMONA: "Zeit MONA:",
                ZeitGespart: "Zeit gespart:",
                GeldDisponent: "Geld Disponent:",
                GeldGespart: "Geld gespart:",
                loadingText: "Wird geladen...",
                Bewerber: "Bewerber",
                übergeordnet: "übergeordnet",
                untergeordnet: "untergeordnet",
                MaxLimit: "Max Limit",
                BewerberDoppelpunkt: "Bewerber:",
                ÜbrigeBewerber: "Übrige Bewerber",
                ÜbrigeBewerberDoppelpunkt: "Übrige Bewerber:",
                RemainingContigentsPerMonth: "Restkontingente pro Monat",
                NoDataAvailable: "Keine Daten verfügbar",
                FinalRemainingContigents: "Letzte verbleibende Kontingente",
                Month: "Monat",
                NumberOfApplicants: "Anzahl der Bewerber",
                ÜbersichtDerBewerber: "Übersicht der Bewerber",
                Tage: "Tage",
                Gesamt: "Gesamt",
                SiebenTage: "7 Tage",
                VierzehnTage: "14 Tage",
                DreißigTage: "30 Tage",
                EmailSMSAnrufe: "Email/SMS/Anrufe",
                bisherVerschickt: "bisher verschickt",
                maximalVerfügbar: "maximal verfügbar",
                WorkflowsInNutzung: "Workflows in Nutzung",
                möglicheWorkflows: "mögliche Workflows",

                //edit-message-modal.html
                NachrichtBearbeiten: "Nachricht bearbeiten",
                Antwortmöglichkeiten: "Antwortmöglichkeiten",
                Texteingabe: "Texteingabe",
                InfoNachricht: "Info Nachricht",
                JaNeinFrage: "Ja/Nein Frage",
                MultiAntwort: "Multi Antwort",
                SingleAntwort: "Single Antwort",
                nurSpracheingabe: "nur Spracheingabe",
                DateiHochladen: "Datei hochladen",
                KalenderEingabe: "Kalendereingabe",
                SingleDate: "Einzelnes datum",
                RangeDate: "Reichweite",
                SingleChoice: "Single Choice",
                DieAntwortExistiertBereits: "Die Antwort existiert bereits.",

                //help.html
                SendenSieUnsIhreFragenKommentareUndIdeen: "Senden Sie uns ihre Fragen, Kommentare und Ideen",
                damitWirStetigDieMONAEngineVerbessernKönnen: " damit wir stetig die MONA Engine verbessern können.",
                SupportGesprächVereinbaren: "Support gespräch vereinbaren",
                SupportTeam: "Support-Team",
                TelefonSupport: "Telefon-Support:",
                EMailSupport: "E-Mail-Support:",
                IhrName: "Ihr Name",
                IhreEMailAdresse: "Ihre E-Mail-Adresse",
                IhreNachricht: "Ihre Nachricht",
                KategorieOptional: "Kategorie (optional)",
                Stellenanzeigen: "Stellenanzeigen",
                Kandidaten: "Kandidaten",
                Einstellungen: "Einstellungen",
                TypOptional: "Typ (optional)",
                FehlerMelden: "Fehler melden",
                FunktionVorschlagen: "Funktion vorschlagen",
                Sonstiges: "Sonstiges",
                IhreTelefonnummerOptional: "Ihre Telefonnummer (optional)",
                OffeneTickets: "Offene Tickets",
                User: "User",
                EMail: "E-Mail:",
                NachrichtDoppelpunkt: "Nachricht:",
                Status: "Status",

                //create-custom-video.html
                Generieren: "Generieren",
                Text: "Text",
                TextDeutsch: "Text Deutsch",
                Avatar: "Avatar",
                Sprache: "Sprache",

                //create-howto.html
                Titel: "Titel",
                GehörtZu: "Gehört zu",
                Beschreibung: "Beschreibung",
                Erklärung: "Erklärung",
                HierErklärenWirDirWieDuEineStellenanzeigeErstellenKannst: "Hier erklären wir dir, wie du eine Stellenanzeige erstellen kannst.",
                UmEineStellenanzeigeZuErstellenBefolgeBitteDieFolgenenSchritteSchritt1: "Um eine Stellenanzeige zu erstellen, befolge bitte die folgenden Schritte. Schritt 1:...",

                //howto.html
                WillkommenBeiDenHowTos: "Willkommen bei den How-To's",
                HierFindestDuAllerleiNützlicheInformationenÜberDieMONAPlattform: "Hier findest du allerlei nützliche Informationen über die MONA Plattform.",
                HerzlichWillkommen: "Herzlich Willkommen",
                NutzeGerneDieSuchfunktionObenOderKlickeDichDurchDasMenüLinksWirFreuenUnsDirHelfenZuKönnen: "Nutze gerne die Suchfunktion oben oder klicke dich durch das Menü links. Wir freuen uns dir helfen zu können.",
                HowToHinzufügen: "How-To hinzufügen",
                VideoGenerieren: "Video generieren",
                Suche: "Suche",
                Kommunikation: "Kommunikation",
                Teamverwaltung: "Teamverwaltung",

                //analytics.html
                analytics: "Analytics",

                //flowchart-graph-modal-module.html
                Fertig: "Fertig",

                //conversations.html
                conversations: "Conversations",

                //insights.html
                Insights: "Insights",

                //interviews.html
                interviews: "Interviews",

                //performance.html
                performance: "Performance",

                //insights -> reporting.html
                Reporting: "Reporting",
                Rahmenbedingungen: "Rahmenbedingungen",
                Intervall: "Intervall",
                ZeitraumWählen: "Zeitraum wählen",
                Täglich: "Täglich",
                Zeitraum: "Zeitraum",
                Monatlich: "Monatlich",
                Quartalsweise: "Quartalsweise",
                Jährlich: "Jährlich",
                required: "*required",
                von: "von",
                bis: "bis",
                Start: "Start",
                Ende: "Ende",
                Heute: "Heute",
                StelleAuswählen: "Stelle auswählen",
                Reset: "Reset",
                Suchen: "Suchen",
                KommunikationsmediumWählen: "Kommunikationsmedium wählen",
                PhaseDesGesprächs: "Phase des Gesprächs",
                Weiterverarbeitung: "Weiterverarbeitung",
                Anrufe: "Anrufe",
                EMails: "E-Mails",
                SMS: "SMS",
                WhatsApp: "WhatsApp",
                Interviews: "Interviews",
                Einstufungstests: "Einstufungstests",
                Lebensläufe: "Lebensläufe",
                Profile: "Profile",
                Analysen: "Analysen",
                NullErgebnisse: "0 Ergebnisse",
                Ergebnisse: "Ergebnisse",
                ExportExcel: "Export Excel",
                POWERBI: "POWER BI",
                ReportingTabelle: "Reporting Tabelle",
                ExportBewerberInterviews: "Export Bewerber & Interviews",
                Stellenanzeige: "Stellenanzeige",
                Datum: "Datum",
                Uhrzeit: "Uhrzeit",
                Dauer: "Dauer",
                Antworten: "Antworten",
                Lebenslauf: "Lebenslauf",
                Terminlink: "Terminlink",
                Browser: "Browser",
                ReportingGraph: "Reporting Graph",
                AuswertungDerBewerberdaten: "Auswertung der Bewerberdaten",
                ExportToPDF: "Export to PDF",
                InterviewStreetDataAnalysis: "Interview Street Data Analysis",
                ConversionGraph: "Conversion Graph",
                AuswertungDerConversionRate: "Auswertung der Conversion-Rate",
                Bewerberanzahl: "Bewerberanzahl",
                Valide: "Valide",
                BewerberMitDokumenten: "Bewerber mit Dokumenten",
                InterviewGestartet: "Interview gestartet",
                BewerberMitTerminoption: "Bewerber mit Terminoption",
                BewerberMitProfil: "Bewerber mit Profil",
                JobtitelUnbekannt: "Jobtitel unbekannt",


                // mona-agent-chat.page.html
                sendSMS: "SMS senden",
                sendMail: "E-Mail senden",
                sendCall: "Anruf senden",
                sendWhatsApp: "WhatsApp senden",
                sendLinkedIn: "LinkedIn senden",
                sendXing: "Xing senden",
                sendFacebook: "Facebook senden",
                sendInstagram: "Instagram senden",
                sendX: "X senden",
                confirm: "Bestätigen",
                FreigegebeneTelefonnummern: "Freigegebene Telefonnummern",
                Landauswählen: "Land auswählen",
                VollständigeNummer: "Vollständige Nummer",
                FreigegebeneEMailAdressen: "Freigegebene E-Mail-Adressen",
                Sprachpräferenzen: "Sprachpräferenzen",
                Bestätigen: "Bestätigen",

                //integrations.html
                IndeedEMailIntegration: "Indeed E-Mail Integration",
                DieEMailIntegrationErmöglichtEsIhnenDieBewerberVonIndeedMitCVParsingFunktionInMONAEinlesenZuLassen: "Die E-Mail Integration ermöglicht es Ihnen die Bewerber von Indeed mit CV-Parsing Funktion in MONA einlesen zu lassen.",
                FürEineIntegrationKontaktierenSiesupportmonaaideUndWirMeldenUnsInKürze: "Für eine Integration, kontaktieren Sie support@mona-ai.de und wir melden uns in Kürze.",
                Indeed: "Indeed",
                Talent360EMailIntegration: "Talent360 E-Mail Integration",
                Talent360: "Talent360",
                ZvooveRecruit: "Zvoove Recruit",
                Aktivieren: "Aktivieren",
                ZvooveBewerberAPIKey: "Zvoove Bewerber API Key",
                ZvooveBewerberAPILink: "Zvoove Bewerber API Link",
                ZvooveStellenAPIKey: "Zvoove Stellen API Key",
                ZvooveStellenAPILink: "Zvoove Stellen API Link",
                MONALoginUsername: "MONA-Login Username",
                MONALoginPasswort: "MONA-Login Passwort",
                KIMatching: "KI-Matching",
                exklusiv: "exklusiv",
                inklusiv: "inklusiv",
                inklusivMitFallback: "inklusiv mit Fallback",
                Zvoove: "Zvoove",
                Aktiviert: "Aktiviert",
                nichtAktiv: "nicht aktiv",
                Talention: "Talention",
                TalentionClientID: "Talention Client-ID",
                TalentionClientSecret: "Talention Client-Secret",
                TalentionAccountID: "Talention Account-ID",
                TalentionIntegrationsweg: "Talention Integrationsweg",
                Alle: "Alle",
                GebieteNL: "Gebiete/NL",
                Jobs: "Jobs",
                TalentionGebieteNiederlassungen: "Talention Gebiete & Niederlassungen",
                BeispielBerlinHamburgMünchen: "Beispiel: Berlin,Hamburg,München",
                KeywordsMitKommaTrennen: "Keywords mit Komma trennen*",
                CompleetAPIPersoprofilerCompana: "Compleet API (Persoprofiler + Compana)",
                GEPEXAPI: "GEPEX API",
                CompleetAPIKeys: "Compleet API Keys",
                StandortAbteilung: "Standort/Abteilung:",
                Compleet: "Compleet",
                initiativRow: "Fallback",
                IndexAnzeigendaten: "Index Anzeigendaten",
                IndexLogin: "Index Login",
                Passwort: "Passwort",
                AlleIntegrationen: "Alle Integrationen",
                SaaS: "SaaS",
                OnPremise: "On Premise",
                Ordner: "Ordner",
                ExklusivePartnervonMONAAI: "Exklusive Partner von MONA AI",
                AllApps: "Alle Apps",
                Bewerberdaten: "Bewerberdaten",
                Verlassen: "Verlassen",
                ready: "Bereit",
                inDevelopment: "In Entwicklung",
                inTesting: "In Test",
                generateApiKey: "API Key generieren",
                DuBistEingeloggtMit: "Du bist eingeloggt mit",

                //delete-job-offer.html
                StellenanzeigeLöschen: "Stellenanzeige löschen",
                SindSieSicherDassSieDieseStellenanzeigeLöschenMöchten: "Sind Sie sicher, dass Sie diese Stellenanzeige löschen möchten?",

                //job-offer-info.html
                zurück: "zurück",
                Kopieren: "Kopieren",
                StellenangebotBearbeiten: "Stellenangebot bearbeiten",
                Stellentitel: "Stellentitel",
                Produktionshelfer: "z.B. Produktionshelfer (m/w/d)",
                Einleitung: "Einleitung",
                BeschreibenSieKurzUndKnappIhrUnternehmen: "Beschreiben Sie kurz und knapp Ihr Unternehmen.",
                Aufgaben: "Aufgaben",
                BeschreibenSieDieGenauenAufgabenUndVerantwortungsbereicheDerStelleDamitDerBewerberVerstehtWasDiesePositionAttraktivMacht: "Beschreiben Sie die genauen Aufgaben und Verantwortungsbereiche der Stelle, damit der Bewerber versteht, was diese Position attraktiv macht.",
                Qualifikation: "Qualifikation",
                BeschreibenSieIhreAnforderungenAnDenKandidaten: "Beschreiben Sie Ihre Anforderungen an den Kandidaten.",
                Benefits: "Benefits",
                NennenSieBenefitsWelcheIhrUnternehmenEinzigartigUndAttraktivMachen: "Nennen Sie Benefits, welche Ihr Unternehmen einzigartig und attraktiv machen.",
                Schlussworte: "Schlussworte",
                WirFreuenUnsÜberDeineBewertung: "z.B. Wir freuen uns über deine Bewertung!",
                GehaltBrutto: "Gehalt (Brutto)",
                Land: "Land",
                Stadt: "Stadt",
                Deutschland: "Deutschland",
                Frankreich: "Frankreich",
                VereinigtesKönigreich: "Vereinigtes Königreich",
                Schweiz: "Schweiz",
                Österreich: "Österreich",
                Belgien: "Belgien",
                Niederlande: "Niederlande",
                Luxemburg: "Luxemburg",
                Spanien: "Spanien",
                Italien: "Italien",
                Polen: "Polen",
                Dänemark: "Dänemark",
                Schweden: "Schweden",
                Norwegen: "Norwegen",
                Finnland: "Finnland",
                Estland: "Estland",
                Lettland: "Lettland",
                Litauen: "Litauen",
                Tschechien: "Tschechien",
                Slowakei: "Slowakei",
                Ungarn: "Ungarn",
                Slowenien: "Slowenien",
                Kroatien: "Kroatien",
                Serbien: "Serbien",
                Ukraine: "Ukraine",
                Russland: "Russland",
                StadtSuchen: "Stadt suchen",
                Vertrag: "Vertrag",
                UploadLogo: "Logo hochladen",
                KlickenZumÄndern: "Klicken zum ändern",
                FarbauswahlKopfzeile: "Farbauswahl-Kopfzeile",
                FarbauswahlKörper: "Farbauswahl-Körper",
                FarbauswahlFürDenFirmentitel: "Farbauswahl für den Firmentitel",
                AvatarKonfigurieren: "Avatar konfigurieren",
                AvatarName: "Avatar Name",
                MONA: "z.B. MONA",
                AvatarAuswählen: "Avatar auswählen",
                Automatisierung: "Automatisierung",
                FragenAnDenBewerber: "Fragen an den Bewerber",
                Parameter: "Parameter",
                Feld: "Feld",
                Fragetyp: "Fragetyp",
                abfragen: "abfragen",
                übersetztIn: "übersetzt in",
                ParameternameExistiertBereits: "Parametername existiert bereits.",
                NachrichtExistiertBereits: "Nachricht existiert bereits.",
                NoMessageAnswersPresent: "Es gibt keine Nachrichtenantworten.",
                defaultJobOfferLanguage: "Standardsprache für Stellenangebote",
                CodingChallenge: "Coding Challenge",
                vorgeschlageneFragen: "vorgeschlagene Fragen",
                ErweiterteEinstellungen: "Erweiterte Einstellungen",
                automatischeBewerbung: "automatische Bewerbung",
                VertragOderDokumenteErklären: "Vertrag oder Dokumente erklären",
                Sprachsteuerung: "Sprachsteuerung",
                CVParsingZuBeginnDesGesprächs: "CV-Parsing zu Beginn des Gesprächs",
                automatischerEinstellungstest: "automatischer Einstellungstest",
                BrowserspracheErzwingen: "Browsersprache erzwingen",
                Feedback: "Feedback vom Bewerber",
                SprachenAuswählen: "Sprachen auswählen",
                Flowchart: "Flowchart",
                ConditionalRoutings: "Conditional Routings",
                FlowchartZurücksetzen: "Flowchart zurücksetzen",
                backToJobOffersHeader: "Zurück zur Stellenübersicht",
                fontStyleLabel: "Schriftart auswählen",
                backToJobOffersMessage: "Möchtest du wirklich zurück zu den Stellenanzeigen?",
                FlowchartZurücksetzenText: "Möchtest du den Flowchart wirklich zurücksetzen?",
                IframeKopieren: "iFrame Kopieren",
                addJobButtonText: "Interview Erstellen",
                FullscreenFlowchart: "Vollbildmodus aktivieren",
                ExportToExcel: "Export als Excel",
                EinstellungstestAnAlleSenden: "Einstellungstest an alle senden",
                Herkunft: "Herkunft",
                Bewerbernummer: "Bewerbernummer",
                Stelle: "Stelle",
                Email: "Email",
                Telefon: "Telefon",
                Bewerbungseingang: "Bewerbungseingang",
                QALab: "Q&A Lab",
                LimitReached: "Limit erreicht",
                LimitReachedMessage: "Sie haben das maximale Limit erreicht",
                MessageStillInFlowchart: "Diese Nachricht ist noch im Flowchart. Bitte entfernen Sie sie zuerst aus dem Flowchart, bevor Sie sie hier deaktivieren.",


                //job-offers.html
                NeuLaden: "Neu Laden",
                Aktiv: "Aktiv",
                Draft: "Vorlagen",
                Test: "Test",
                Gelöscht: "Gelöscht",
                ImportierenVonStellenanzeigen: "Importieren von Stellenanzeigen",
                ExportierenVonStellenanzeigen: "Exportieren von Stellenanzeigen",
                Reaktivieren: "Reaktivieren",
                Verträge: "Verträge",
                Aktualisiert: "Aktualisiert",
                Stellenangebot: "Stellenangebot",
                Standort: "Standort",
                URL: "URL",
                Iframe: "Iframe",
                StatusDerBereitstellung: "Status der Bereitstellung",

                //new-job-offer.html
                SpeichernAlsTemplate: "Als Template Speichern",
                StellenangebotErstellen: "Stellenangebot erstellen",
                HINWEIS: "Hinweis",
                SAVE_AS_DRAFT: "Als Entwurf speichern",
                SAVE_IN_TEST_MODE: "im Testmodus speichern",
                InfoMessageForMessagePresentInFlowhchart: "Bitte löschen Sie zunächst die Nachricht aus dem Flussdiagramm.",

                //qna-lab.html
                VorgeschlageneFragen: "Vorgeschlagene Fragen",
                Aktualisieren: "Aktualisieren",
                StellenanzeigeBearbeiten: "Stellenanzeige bearbeiten",
                GenerierenSieFragen: "Generieren Sie Fragen",
                Hinzufügen: "Hinzufügen",
                AILabTrainingPanel: "AI Lab Training Panel",
                Keyword: "Keyword",
                Anzahl: "Anzahl",
                Keywords: "Keywords",
                deutsch: "deutsch",

                //logo.html
                ChangeCompanyLogoJPGPNG: "Logo ändern (JPG/PNG)",
                BildHinzufügen: "Bild hinzufügen",
                BitteGebenSieEinKleineresBildEin: "Bitte laden Sie ein kleineres Bild hoch.",
                Entfernen: "Entfernen",

                //message -> configure-message.html
                AlteNachricht: "Alte Nachricht",
                NeueNachricht: "Neue Nachricht",
                neueNachrichtEingeben: "neue Nachricht eingeben",

                //mona-communication-list.html
                Recruiting: "Recruiting",
                Onboarding: "Onboarding",
                Vertrieb: "Vertrieb",
                Ziel: "Ziel",
                Dokumente: "Dokumente",
                BewerberId: "Bewerber ID",
                Kontakt: "Kontakt",
                EmailPreview: "Email Vorschau",
                ViewEmail: "Email anzeigen",
                Company: "Firma",
                Location: "Standort",
                Positon: "Position",
                URLLink: "URL Link",
                CompanyLink: "Firmenlink",
                CompanyReport: "Firmenreport",
                CompanyPhone: "Firmen Telefon",
                ContactPerson: "Kontaktperson",
                ContactPersonEmail: "Kontaktperson Email",
                ContactPersonPhone: "Kontaktperson Telefon",
                Goal: "Ziel",
                AcquisitionSubject: "Akquise Betreff",
                MatchingPunktzahl: "Matching Punktzahl",
                Erklaerung: "Erklärung",
                Reflexion: "Reflexion",
                Unternehmensdetails: "Unternehmensdetails",

                //news.html
                UpdateLOG: "Update - LOG",
                TageHer: "Tage her",
                Plattform: "Plattform",
                Verbesserung: "Verbesserung",
                BugFix: "Bug-Fix",

                //preview-file.html
                Herunterladen: "Herunterladen",
                abgeschlossenesStudium: "abgeschlossenes Studium",
                mehrAls6MonateBerufserfahrung: "mehr als 6 Monate Berufserfahrung",
                Deutschkenntnisse: "Deutschkenntnisse",
                Übernehmen: "Übernehmen",
                FehlerBeimLadenDerDatei: "Fehler beim Laden der Datei...",

                //profile.html
                AccountEinstellungen: "Account Einstellungen",
                UnternehmensInformationen: "Unternehmens Informationen",
                WebseitenLink: "Webseiten Link",
                Farbauswahl: "Farbauswahl",
                FarbeAuswählen: "Farbe auswählen",
                SpracheAuswählen: "Sprache auswählen",
                Kontaktierung: "Kontaktierung",
                Standorte: "Standorte",
                Straße: "Straße",
                Hausnr: "Hausnr",
                PLZ: "PLZ",
                Ort: "Ort",
                EMinusMail: "E-Mail",
                calendlyLink: "Calendly Link",
                Datenschutz: "Datenschutz",
                GebenSieHierIhrenDatenschutzLinkEin: "Geben Sie hier Ihren Datenschutz-Link ein",
                MatchingProfilvertrieb: "Matching & Profilvertrieb",
                FallbackFürInterviews: "Fallback für Interviews",
                MatchingExtern: "Matching extern",
                MinimumMatchingErgebnis: "Minimum Matching Ergebnis",
                MatchingKriterium: "Matching Kriterium",
                MatchingTyp: "Matching Typ",
                MatchingGewicht: "Matching Gewicht",
                Optionen: "Optionen",
                EUWorker: "EU-Worker - lebt in EU, soll vermittelt werden nach DE. Lebensstandort des Bewerbers wird nicht für Matching verwendet wenn aktiv. Gilt nicht für Personen, die in Deutschland leben.",
                Hard: "Hard",
                Soft: "Soft",
                ausgewähltIstDannMussDasKriteriumErfülltWerdenDamitEinMatchVorliegt: "ausgewählt ist, dann muss das Kriterium erfüllt werden, damit ein Match vorliegt.",
                ausgewähltIstDannMussDasKriteriumNichtErfülltWerdenDamitEinMatchVorliegt: "ausgewählt ist, dann muss das Kriterium nicht erfüllt werden, damit ein Match vorliegt.",
                EineGewichtungVon1IstDieNiedrigsteGewichtungFürEinMatchingkriterium: "Eine Gewichtung von 1 ist die niedrigste Gewichtung für ein Matchingkriterium.",
                EineGewichtungVon200IstDieNiedrigsteGewichtungFürEinMatchingkriterium: "Eine Gewichtung von 200 ist die niedrigste Gewichtung für ein Matchingkriterium.",
                insgesamtKönnen20KriterienAngepasstWerdenGematchedWerdenInsgesamt25KriterienUndDieInterviewergebnisse: "Insgesamt können 20 Kriterien angepasst werden. Gematched werden insgesamt 25 Kriterien und die Interviewergebnisse",
                BewerberprofilKonfigurieren: "Bewerberprofil konfigurieren",
                ProfilbildAutomatischErgänzen: "Profilbild automatisch ergänzen",
                SprachenMüssenEnthaltenSein: "Sprachen müssen enthalten sein",
                BerufserfahrungMussEnthaltenSein: "Berufserfahrung muss enthalten sein",
                AtLeastThreeJobs: "mindestens drei Berufspositionen",
                WennDerMatchingTyp: "Wenn der Matching-Typ",
                Zertifikate: "Zertifikate",
                Softskills: "Softskills",
                ProjektkenntnisseMüssenEnthaltenSein: "Projektkenntnisse müssen enthalten sein",
                AufenthaltserlaubnisArbeitserlaubnisMussVorliegenOderAbgefragtSein: "Aufenthaltserlaubnis/Arbeitserlaubnis muss vorliegen oder abgefragt sein",

                //applicant-create-short-info.html
                BewerberAnlegen: "Bewerber anlegen",
                BewerberSchnellerfassung: "Bewerber-Schnellerfassung",
                Strasse: "Straße",
                Hausnummer: "Hausnummer",
                Wohnort: "Wohnort",
                TelefonMobil: "Telefon / Mobil",
                Geburtsdatum: "Geburtsdatum",
                ChoosePDFFile: "PDF-Datei wählen",


                //applicants-downloader.html
                AngabenZurPerson: "Angaben zur Person",
                Geschlecht: "Geschlecht",
                Adresse: "Adresse",
                Geburtsland: "Geburtsland",
                Geburtsort: "Geburtsort",
                Staatsangehörigkeit: "Staatsangehörigkeit",
                Sprachen: "Sprachen",
                BestätigungscodeSMS: "Bestätigungscode SMS",
                EmailBestätigt: "Email bestätigt",
                KFZ: "KFZ",
                Führerschein: "Führerschein",
                EMailVonMONAGesendet: "E-Mail von MONA gesendet",
                SMSVonMONAGesendet: "SMS von MONA gesendet",
                TelefonanrufVonMONAGesendet: "Telefonanruf von MONA gesendet",
                Ausbildung: "Ausbildung",
                Monate: "Monate",
                Abschluss: "Abschluss",
                höchsterAbschluss: "höchster Abschluss",
                Hauptschulabschluss: "Hauptschulabschluss",
                MittlereReife: "Mittlere Reife",
                OhneAbschluss: "Ohne Abschluss",
                Abitur: "Abitur",
                höchsteAusbildung: "höchste Ausbildung",
                ohneAusbildung: "ohne Ausbildung",
                Berufsausbildung: "Berufsausbildung",
                mitAbschluss: "mit Abschluss",
                Bachelor: "Bachelor",
                Promotion: "Promotion",
                Diplom: "Diplom",
                Meister: "Meister",
                Bemerkungen: "Bemerkungen",
                Qualifikationen: "Qualifikationen",
                Vorschläge: "Vorschläge",
                Match: "- Match",
                Berufserfahrung: "Berufserfahrung",
                Sprachtest: "Sprachtest",
                GrammatikUndSatzbau: "Grammatik und Satzbau",
                Wortschatz: "Wortschatz",
                KohärenzUndKlarheit: "Kohärenz und Klarheit",
                Anmerkungen: "Anmerkungen",
                Talent360Info: "Talent360 Info",
                Einsatzort: "Einsatzort",
                Kandidat: "Kandidat",
                BewerberLog: "Bewerber Log",
                MonaLog: "MONA Log",
                TranskriptionDesVideos: "Transkription des Videos.",
                VideoAntwort: "Video Antwort",
                TranskriptionDesAudios: "Transkription des Audios.",
                EigeneFelder: "Eigene Felder",
                EinstellungstestErgebnisse: "Einstellungstest Ergebnisse",
                Punkte: "Punkte",
                Aufgabe: "Aufgabe",
                erreichtePunkte: "erreichte Punkte",
                keineDatenVorhanden: "keine Daten Vorhanden",

                //applicant-info.html
                Schließen: "Schließen",
                Archivieren: "Archivieren",
                Editieren: "Editieren",
                Exportieren: "Exportieren",
                EinstellungstestSenden: "Einstellungstest senden",
                VertragErstellen: "Vertrag erstellen",
                SendeDokumentAnBewerber: "Sende Dokument an Bewerber",
                KomplettPDFAbrufen: "Komplett-PDF abrufen",
                ProzessStarten: "Prozess starten",
                Stufe2: "Stufe 2",
                Stufe3: "Stufe 3",
                Handy: "Handy",
                Anruf: "Anruf",
                LogAlsPDFExportieren: "Log als PDF exportieren",
                VideoAnsehen: "Video ansehen",
                eigenesFeldErgänzen: "eigenes Feld ergänzen",
                Dateien: "Dateien",
                Dateiname: "Dateiname",
                EMailSchicken: "E-Mail schicken",
                SMSSchicken: "SMS schicken",
                anrufenLassen: "anrufen lassen",
                KundeWarSehrZufrieden: "Kunde war sehr zufrieden...",
                abgeschlosseneBerufsausbildungAlsMechaniker: "abgeschlossene Berufsausbildung als Mechaniker...",
                Erfahrung: "Erfahrung",
                zehnJahreErfahrung: "10 Jahre Erfahrung...",
                Unternehmen: "Unternehmen",
                FieldName: "Feld Name",
                FieldValue: "Feld Wert",
                Remove: "Entfernen",
                FeldErgänzen: "Feld ergänzen",
                Submit: "Bestätigen",
                DateiHierherZiehen: "Datei hierher ziehen",
                ProfilErstellt: "Profil erstellt",
                noData: "Keine Daten verfügbar",
                erfolg: "Erfolg",
                fehler: "Fehler",
                dateDesc: "Datum (Absteigend)",
                dateAsc: "Datum (Aufsteigend)",
                typeDesc: "Typ (Absteigend)",
                typeAsc: "Typ (Aufsteigend)",

                //applicants.html
                ArchivierteAusblenden: "Archivierte ausblenden",
                ArchivierteAnzeigen: "Archivierte anzeigen",
                AlleZeigen: "Alle zeigen",
                nichtAlleZeigen: "nicht alle zeigen",
                Schnellerfassung: "Schnellerfassung",
                BewerberInDenLetzten30Tagen: "Bewerber in den letzten 30 Tagen",
                LadeAlle: "Lade Alle",
                exportExcel: "Export Excel",
                exportToExcelAlle: "Export nach Excel - Alle",

                //reporting.html
                ReportingErstellen: "Reporting erstellen",
                erstellen: "Erstellen",
                Export: "Export",

                //settings.html
                DatenImport: "Daten Import",
                DataWarehouse: "Data Warehouse",
                Integrationen: "Integrationen",
                Blacklist: "Blacklist",
                BewerberLeadgenerierung: "Bewerber-Leadgenerierung",
                KundenLeadgenerierung: "Kunden-Leadgenerierung",
                KIStudio: "KI-Studio",
                SucheNachNameOderAktionOderMedium: "Suche nach Name oder Aktion oder Medium",

                //team-management.html
                Team: "Team",
                TeammitgliederVerwalten: "Teammitglieder verwalten",

                //upload.html
                BewerberExcelImport: "Bewerber Excel-Import",
                IndexStellenanzeigen: "Index Stellenanzeigen",
                TalentionUpload: "Talention Upload",
                IndexUpload: "Index Upload",
                BewerberUpload: "Bewerber Upload",
                ExcelStandardVorlageDownloaden: "Excel-Standard-Vorlage downloaden",
                BewerberKontaktieren: "Bewerber kontaktieren",
                BewerberÜbernehmen: "Bewerber übernehmen",
                BewerberImportListe: "Bewerber-Import Liste",
                Bewerberin: "Bewerber/in",
                Mobilnummer: "Mobilnummer",
                TalentionDatenHochladen: "Talention Daten hochladen",
                wurdenAusgelesenUndAnalysiertMitKI: "wurden ausgelesen und analysiert mit KI",
                IndexAnzeigendatenJSONUpload: "Index Anzeigendaten JSON Upload",

                //constants.ts
                PERSONALIZE_MESSAGE: "Diese standardmäßige personalisierte Nachricht würde angezeigt, wenn Sie keine personalisierte Nachricht festlegen. Wir möchten Sie daher bitten, Ihre eigene personalisierte Nachricht festzulegen und auf die Schaltfläche „Speichern“ zu klicken.",
                GENERATEQAQUESTIONS_LOADING_MESSAGE: "Bitte warten Sie 15 Sekunden, während wir Fragen generieren.",
                GENERATEQA_PROMPT_MESSAGE: "Listen Sie die 10 wichtigsten Fragen auf, die Sie einem Personalvermittler als Bewerber für die Stelle stellen sollten ",
                JOB_TITLE_NOTSET_MESSAGE: "Um vorgeschlagene Fragen zu generieren, geben Sie bitte hier den Stellentitel ein.",
                REFRASEQANDAQUESTIONS_TEXTBLOCK: "Nachfolgend sende ich dir mit Komma seperierte Fragen. Formuliere jede Fragen in 10 verschiedenen weisen um. Formuliere sie so, als würde sie ein Bewerber an einen Recruiter stellen. Gebe mir das Ergebnis als valides Json format zurück mit den von mir gesendeten Fragen als Key und den von dir umgewandelten Fragen als value. Das json objekt soll wie folgt aufgebaut werden. {\"frage1\": [\"\"]}. Lösche alles, was verhindert, dass es ein valides JSON objekt wird. Ersetze 'frage' mit den Fragen, die ich dir gebe und den Array '[\"\"]' mit den Ergebnissen von dir. Gib mir keine weiteren Erklärungen: ",
                AIALERT_MESSAGE: "Das KI-Modell wird jetzt trainiert. Dies dauert ca. 10 Minuten. Anschließend kann das Gespräch geführt werden.",
                AIFEEDBACKONPREQUISITES_MESSAGE: "Nachfolgend ist der Text für eine Stellenanzeige. Welche Fragen würdest du an einen Bewerber stellen, um herauszufinden, ob er die Anforderungen an die Stellenanzeige erfüllt? Formuliere maximal 10 Fragen: ",
                VALIDATE_CANTLINKTOITSELF_MESSAGE: "Du kannst keinen Knoten auf sich selbst verlinken",
                VALIDATE_NOCONDITIONS_ONSTARTNODE_MESSAGE: "Zum Startknoten können keine Bedingungen hinzugefügt werden",
                MAXIMUM_QUESTIONS_REACHED_MESSAGE: "Maximale Anzahl von Fragen erreicht",
                VALIDATECYCLE_WITHOUTCONDITIONS_MESSAGE: "Zyklen ohne Bedingung sind nicht erlaubt",
                ERROR_CLOUDFUNCTION_MESSAGE: "Fehler beim Aufrufen der Google Cloud Function: ",
                //   GENERATE_ASSESSMENT_ANSWERS:  "Bitte generieren Sie 4 Multiple-Choice-Optionen für die folgende Frage. Von den Optionen müssen 3 falsch und 1 richtig sein. Bitte geben Sie die Antwort als JSON-Objekt zurück, bestehend aus der Frage als „frage“, 4 Optionen als „optionen“ und der richtigen Antwort als „richtig“. Schreiben Sie Optionen im JSON als [„Option 1“, „Option 2“, „Option 3“, „Option 4“]",
                GENERATE_ASSESSMENT_ANSWERS: "„Bitte generieren Sie 4 Multiple-Choice-Optionen für die folgende Frage. Von den Optionen müssen 3 falsch und 1 richtig sein. Bitte geben Sie die Antwort als JSON-Objekt zurück, bestehend aus der Frage als „Frage“, 3 falschen Optionen als „falsch“ und der richtigen Antwort als „richtig“. Schreiben Sie die falschen Optionen in JSON als [„Option 1“, „Option 2“, „Option 3“]“; Hier ist die Frage:",
                //   GENERATE_ASSESSMENT_QUESTIONS_ANSWERS_ONLY_TITLE: "Lesen Sie den Titel und stellen Sie genau 10 nicht ganz einfache Fragen. Für diese 10 Fragen sollte es 4 Optionen geben, von denen 3 falsch und 1 richtig sein sollten. Geben Sie es als einfaches JSON-Objektformat zurück. Befolgen Sie strikt diese Beispielvorlage: {frage: \"example questions\", optionen: [\"option 1\", \"option 2\", \"option 3\", \"option 4\"], richtig: \"option 3\"}. Hier ist der Titel der Rezension:",
                //   GENERATE_ASSESSMENT_QUESTIONS_ANSWERS_ONLY_TITLE: "Lesen Sie den Titel und stellen Sie genau 10 nicht ganz einfache Fragen. Für diese 10 Fragen sollte es 4 Optionen geben, von denen 3 falsch und 1 richtig sein sollten. Geben Sie es als einfaches Array im JSON-Objektformat zurück. Befolgen Sie strikt diese Beispielvorlage: [{„frage“: „Beispielfragen“, „optionen“: [„Option 1“, „Option 2“, „Option 3“, „Option 4“], „richtig“: „Option 3“}, {„frage“: „Beispielfragen“, „optionen“: [„Option 1“, „Option 2“, „Option 3“, „Option 4“], „richtig“: „Option 3“}]. Hier der Titel der Rezension:",
                GENERATE_ASSESSMENT_QUESTIONS_ANSWERS_ONLY_TITLE: "Lesen Sie den Titel und stellen Sie genau 10 nicht ganz einfache Fragen. Für diese 10 Fragen sollte es 4 Optionen geben, von denen 3 falsch und 1 richtig sein sollten. Geben Sie es als einfaches Array im JSON-Objektformat zurück. Halten Sie sich strikt an diese Beispielvorlage: [{„frage“: „Beispielfragen“, „falsch“: [„Option 1“, „Option 2“, „Option 3“], „richtig“: „Option 4“ }, {„frage“: „Beispielfragen“, „falsch“: [„Option 1“, „Option 2“, „Option 3“], „richtig“: „Option 4“}]. Hier der Titel der Rezension:",
                PREVIEW_NOT_AVAILABLE_HEADER: "Vorschau nicht verfügbar",
                PREVIEW_NOT_AVAILABLE_MESSAGE: "Ihre Datei wurde hochgeladen, kann jedoch nicht in der Vorschau angezeigt werden.",
                DATA_PROTECTION_URL_INVALID_MESSAGE: "Die Datenschutz-URL ist ungültig. Bitte geben Sie eine gültige URL ein und versuchen Sie es erneut.",
                DATA_PROTECTION_URL_NOTE_MESSAGE: "Bitte geben Sie eine gültige URL ein.",
                JOB_OFFER_RESTORED: "Stellenangebot wurde reaktiviert",
                NO_JOB_OFFER_RECORDS_TEXT: "Keine Stellenangebote gefunden",
                NO_APPLICANT_RECORDS_TEXT: "Keine Bewerber gefunden",
                APPLICANT_DELETED_SUCCESSFULLY: "Bewerber erfolgreich gelöscht",
                LOADING_TEXT_DASHBOARD: "Wird geladen...",
                STELLENANZEIGE_ERSTELLEN: "Interview erstellen",
                IFRAME_KOPIEREN: "IFrame kopieren",
                EMAIL_TEXT: "Hey $firstname $lastname,\n\ndu hast dich erfolgreich bei der Firma $companyname beworben.\n\nKlicke auf den untenstehenden Link, um dein Interview mit unserer digitalen Mitarbeiterin zu starten.\n\n$interactionURL",
                SMS_TEXT: "Hey $firstname $lastname,\n\ndu hast dich erfolgreich bei der Firma $companyname beworben.\n\nKlicke auf den untenstehenden Link, um dein Interview mit unserer digitalen Mitarbeiterin zu starten.\n\n$interactionURL",
                BETREFF_TEXT: "Starte jetzt dein Interview",

                STATUS_INPRÜFUNG: "in Prüfung",
                STATUS_INTERVIEW_ABGESCHLOSSEN: "Interview abgeschlossen",
                STATUS_BEGINNT_BEWERBUNGSPROZESS: "beginnt Bewerbungsprozess",
                STATUS_EINGESTELLT: "Eingestellt",
                STATUS_VERMITTELT: "Vermittelt",
                STATUS_ABSAGE_DURCH_DISPONENT: "Absage durch Disponent",
                STATUS_ABSAGE_DURCH_KUNDE: "Absage durch Kunde",
                STATUS_ABSAGE_DURCH_BEWERBER: "Absage durch Bewerber",
                STATUS_EINLADUNG_ZUM_VG: "Einladung zum VG",
                STATUS_NOCHMAL_ANRUFEN: "nochmal anrufen",
                STATUS_PROBEARBEIT: "Probearbeit",
                STATUS_PROFIL_AN_KUNDEN: "Profil an Kunden weitergeleitet",
                STATUS_RÜCKRUF_FÜR_VEREINBARUNG: "Rückruf für Vereinbarung VC/Interview",
                STATUS_VC_INTERVIEW_NICHT_WAHRGENOMMEN: "VC/Interview nicht wahrgenommen",
                STATUS_VC_INTERVIEW_VEREINBART: "VC/Interview vereinbart",
                STATUS_VG_DURCHGEFÜHRT: "VG durchgeführt",
                STATUS_VG_NICHT_ERSCHIENEN: "VG nicht erschienen",
                STATUS_BEFINDET_SICH_AKTUELL_IM_INTERVIEW: 'befindet sich aktuell im Interview',
                STATUS_EINSTELLUNGSTEST_ABGESCHLOSSEN: "Einstellungstest abgeschlossen",
                SPECIFY_NODES: 'Du musst mindestens einen Knoten im Flowchart zwischen dem Start Knoten und dem End Knoten angeben.',
                SPECIFY_END_NODES: "Du musst einen End Session Knoten im Flowchart angeben.",
                IS_DANKE_MESSAGE_SAVED: "Bitte speichern Sie die \"Danke\" Nachricht im Tab \"Automatisierung\"",
                IS_SPRACHE_MESSAGE_SAVED: "Bitte speichern Sie die \"Sprache\" Nachricht im Tab \"Automatisierung\"",
                IS_CVUpload_MESSAGE_SAVED: "Bitte speichern Sie die \"CV Upload\" Nachricht im Tab \"Automatisierung\"",
                STELLENANZEIGE_ERSTELLEN_MESSAGE: "Deine Änderungen in den Fragen konnten im Flowchart nicht übernommen werden. Bitte überprüfe den Flowchart.",
                ADD_END_SESSION_NODE_MESSAGE: "Füge ein End Session Node im Flowchart hinzu!",
                DELETE_MESSAGE: "Möchten Sie wirklich die Nachricht löschen: ",
                NEED_TO_ADD_END_SESSION: 'Add an end session node to your workflow',
                ERROR_WHEN_OPEN_APPLICANT: 'Fehler beim Öffnen des Bewerbers',
                UPDATE_JOB_ADVERTISEMENT: 'Stellenanzeige aktualisieren',
                PUBLISH: "Veröffentlichen",
                ASK_QUESTIONS_PROMT: "Hast du noch Fragen an mich? Du kannst die Fragen jetzt stellen. Nutze dazu das Textfeld oder die Spracheingabe.",
                JOB_AD_TEXT: "Unten ist der Text für eine Stellenausschreibung. Welche Fragen würden Sie einem Bewerber stellen, um festzustellen, ob er die Anforderungen für die Stellenausschreibung erfüllt? Formulieren Sie maximal 10 Fragen und geben Sie an, ob es sich um eine Ja-, Nein- oder Eingabefeldfrage handelt: ",

                // flowchart.ts
                selectLocation: "Standort auswählen",
                Handynummer: "Handynummer",
                Abschicken: "Abschicken",
                DELETE_FLOWCHART: 'Flowchart wirklich löschen?',
                EXISTIERT_BEREITS: 'existiert bereits',
                KNOTEN_HINZUFUGEN: 'Knoten hinzufügen',
                VERBINDUNG_AKTUALISIEREN: 'Verbindung aktualisieren'
        }
}
export const roles = [
    {
        name: "Recruiter",
        id: 1,
        availableMenuPoints: [
            "/dashboard",
            "/job-offers",
            "/applicants",
            "/insights"
        ]
    },
    {
        name: "Vertrieb",
        id: 2,
        availableMenuPoints: [
            "/dashboard",
            "/applicants",
            "/mona-communication-list",
            "/insights"
        ]
    },
    // marketing
    {
        name: "Marketing",
        id: 3,
        availableMenuPoints: [
            "/dashboard",
            "/job-offers",
            "/mona-communication-list",
            "/insights"
        ]
    },
    {
        name: "Niederlassungsleiter",
        id: 4,
        availableMenuPoints: [
            "/dashboard",
            "/job-offers",
            "/applicants",
            "/mona-communication-list",
            "/insights",
            "/settings",
            "/team-management"
        ]
    },
    {
        name: "SuperAdmin",
        id: 5,
        availableMenuPoints: [
            "/dashboard",
            "/job-offers",
            "/applicants",
            "/mona-communication-list",
            "/insights",
            "/settings",
            "/team-management",
            "/profile"
        ]
    }
]
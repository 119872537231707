import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { roles } from '../team-management/roles';
import { environment } from 'src/environments/environment';

interface MenuItem {
    title: string;
    url: string;
    icon?: string;
    photo?: string;
}

@Injectable({
    providedIn: 'root',
})
export class MenuService {
    private menuItemsSource = new BehaviorSubject<MenuItem[]>([]);
    menuItems$ = this.menuItemsSource.asObservable();

    public userRole: 'free' | 'enterprise' = 'free'; // Default to 'free'
    userRole$ = new BehaviorSubject<string>(this.userRole);

    public planLimits = {};
    planLimits$ = new BehaviorSubject<any>(this.planLimits);

    public AccountSettings = [
        { title: 'Profil', url: '/profile', photo: '../assets/flaticons/profile.png' },
    ];
    AccountSettings$ = new BehaviorSubject<any>(this.AccountSettings);

    // Free users menu
    private freeUserMenu: MenuItem[] = [
        // { title: 'Dashboard', url: '/dashboard', photo: '../assets/icon/dashboard.png' },
        // { title: 'Insights', url: '/insights', photo: '../assets/flaticons/insights.png' },
        // { title: 'Einstellungen', url: '/settings', photo: '../assets/flaticons/settings.png' },
    ];

    // Enterprise users menu
    private enterpriseUserMenu: MenuItem[] = [
        { title: 'Dashboard', url: '/dashboard', photo: '../assets/icon/dashboard.png' },
        { title: 'Interviews', url: '/job-offers', photo: '../assets/icon/joboffers.png' },
        { title: 'Kandidaten', url: '/applicants', photo: '../assets/icon/candidates.png' },
        { title: 'Kommunikation', url: '/mona-communication-list', photo: '../assets/flaticons/negotiation.png' },
        { title: 'Insights', url: '/insights', photo: '../assets/flaticons/insights.png' },
        { title: 'Einstellungen', url: '/settings', photo: '../assets/flaticons/settings.png' },
    ];

    public appPages2 = [
        { title: 'Dashboard', url: '/dashboard', photo: '../assets/icon/dashboard.png' },
        { title: 'Interviews', url: '/job-offers', photo: '../assets/icon/joboffers.png' },
        { title: 'Kandidaten', url: '/applicants', photo: '../assets/icon/candidates.png' },
        { title: 'Kommunikation', url: '/communication-dashboard', photo: '../assets/flaticons/bullhorn.png' },
        { title: 'Teamverwaltung', url: '/team-management', photo: '../assets/flaticons/manager.png' },
        { title: 'SalesEmails', url: '/automations/email', photo: '../assets/flaticons/manager.png' },
    ];

    constructor() {
        this.setMenuForRole(this.userRole); // Set menu based on the user role (default is 'free')
    }

    setPlanLimits(limits: any) {
        this.planLimits = limits;
        this.planLimits$.next(limits);
    }

    // Dynamically add a menu item
    addMenuItem(newItem: MenuItem) {
        const currentItems = this.menuItemsSource.value;
        this.menuItemsSource.next([...currentItems, newItem]);
    }

    // Set specific menu items
    setMenuItems(items: MenuItem[]) {
        this.menuItemsSource.next(items);
    }

    setMenuItemsByUrl(urls: string[]) {
        let menuItems = this.enterpriseUserMenu.filter((menuItem) => urls.includes(menuItem.url));
        this.setMenuItems(menuItems);
    }

    setUserRoleByRole(role: number) {
        let roleObj = roles.find((roleItem) => roleItem.id === role);
        console.log("roleObj", roleObj);
        if (roleObj) {
            this.setMenuItemsByUrl(roleObj.availableMenuPoints);
        }
        if(roleObj.id != 5) {
            console.log("roleObj.id", roleObj.id);
            this.AccountSettings = [];
        }
    }

    // Set the user role and corresponding menu
    setUserRole(role: 'free' | 'enterprise') {
        this.userRole = role;
        this.setMenuForRole(role);
    }

    // Method to switch menu based on the user role
    public setMenuForRole(role) {
        switch (role) {
            case 'free':
                this.setMenuItems(this.freeUserMenu);
                break;
            case 'pro':
                this.setMenuItems(this.freeUserMenu);
                break;
            case 'professional':
                this.setMenuItems(this.freeUserMenu);
                break;
            case 'enterprise':
                this.setMenuItems(this.enterpriseUserMenu);
                break;
            default:
                this.setMenuItems(this.freeUserMenu); // Default to free menu
                break;
        }
    }
}
